/**
 * nav
 */

import $ from 'jquery';
import 'jquery.pubsub';

import Page from '~/page/page';

export default class Navigation extends Page {
  init() {
    this.elements({
      trigger: '.js-nav-toggle',
      dropmenu: '.js-nav-drop'
    });

    $.on('header.fixed', () => this.closeOpened());
    $.on('header.unfixed', () => this.closeOpened());
  }
  events() {
    this.$.document.on('click', e => this.handler(e));
    this.$.window.on('keydown', e => this.handler(e));
    this.$.dropmenu.on('drop.close', e => {
      const $drop = $(e.currentTarget);
      const $target = $drop.siblings(this.$.trigger);

      this.close($target, $drop);
    });
  }
  handler(e) {
    const methods = {
      click: this.click,
      keydown: this.keydown
    };

    const type = e.type;
    const method = methods[type];

    method.call(this, e);
  }
  click(e) {
    const $target = $(e.target);

    if ($target.is(this.$.trigger)) {
      e.preventDefault();
      const $drop = $target.siblings(this.$.dropmenu);

      if ($target.is('.is-active')) {
        this.close($target, $drop);
        return;
      }

      this.closeOpened();
      this.open($target, $drop);
    } else {
      if (!$target.closest(this.$.dropmenu).length) {
        this.closeOpened();
      }
    }
  }
  keydown(e) {
    if (e.keyCode === 27) {
      this.closeOpened();
    }
  }
  open($target, $drop) {
    $target.addClass('is-active');
    $drop.fadeIn(200, () => {
      $drop.addClass('is-open');
    });
  }
  close($target, $drop) {
    $target.removeClass('is-active');
    $drop.fadeOut(200).removeClass('is-open');
  }
  closeOpened() {
    this.$.trigger.removeClass('is-active');
    this.$.dropmenu
      .filter('.is-open')
      .fadeOut(200)
      .removeClass('is-open');
  }
}

Page.mount(Navigation, '.js-nav');
