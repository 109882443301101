/**
 * section
 */

import Keyframes from 'js/lib/keyframes';
import Page from '~/page/page';

export default class Section extends Page {
  init() {
    this.state = {
      active: false,
      visible: false
    };

    setTimeout(() => {
      this.observer();
    }, 200);
  }
  observer() {
    const handler = entries => {
      const [entry] = entries;

      if (entry.isIntersecting) {
        this.state.visible = true;

        if (!this.state.active && !this.skip()) {
          this.appear();
          this.state.active = true;
        }
      } else {
        this.state.visible = false;
      }
    };

    const observer = Page.getObserver(handler, {
      rootMargin: '-15%'
    });

    observer.observe(this.$.root.get(0));
  }
  skip() {
    return this.$.root.is('[data-skip]');
  }
  appear() {
    const timeline = Keyframes.getTimeline(this.$.root, {
      offset: 600,
      duration: 800
    });

    timeline.play();
  }
}

Page.mount(Section, '.js-section');
