/**
 * scroll
 */

import $ from 'jquery';
import anime from 'animejs';
import Page from '~/page/page';
// import SmoothScroll from './jquery.scrollSpeed';

export default class Scroll extends Page {
  init() {
    this.state = {
      animating: false
    };

    this.elements({
      anchors: '[data-scroll-to]'
    });

    // this.scrollbar();
  }
  events() {
    this.$.anchors.on('click', { type: 'scrollTo' }, e => this.handler(e));
  }
  handler(e) {
    const type = e.data.type;
    const methods = {
      scrollTo: this.scrollToHandler
    };

    methods[type].call(this, e);
  }
  scrollbar() {
    SmoothScroll({
      animationTime: 700,
      stepSize: 70,
      keyboardSupport: true
    });
  }
  scrollToHandler(e) {
    e.preventDefault();

    if (this.state.animating) return false;

    const $current = $(e.target);
    const $target = $($current.data('scrollTo'));

    this.scrollTo($target);
  }
  scrollTo($target, done) {
    if (!$target.length) return;

    this.state.animating = true;

    const scrollTop = $target.offset().top - 20;
    const targets = $('body, html').get();
    const duration = 800;

    anime({
      targets,
      scrollTop,
      duration,
      easing: 'easeInOutCubic',
      complete: () => {
        if (done) done();
        this.state.animating = false;
      }
    });
  }
}

Page.mount(Scroll, '.js-body');
