import Page from '~/page/page';
import ScrollBooster from 'scrollbooster';

export default class History extends Page {
  init() {
    this.state = {};
    this.elements({
      list: '.js-history-list'
    });

    this.initScrollBooster();
  }
  events() {}
  initScrollBooster() {
    const viewport = this.$.root.get(0);
    const content = this.$.list.get(0);

    new ScrollBooster({
      viewport,
      content,
      mode: 'x',
      onUpdate: data => {
        const wWidth = this.$.window.outerWidth();
        if (wWidth > 1179 || wWidth < 768) return;

        content.style.transform = `translateX(${-data.position.x}px)`;
      }
    });
  }
}

Page.mount(History, '.js-history');
