import anime from 'animejs';
import Page from '~/page/page';

import $ from 'jquery';
import 'jquery.pubsub';

export default class Header extends Page {
  init() {
    this.state = {
      fixed: false,
      animating: false
    };

    this.prevOffset = 0;

    this.height = {
      default: this.$.root.outerHeight(),
      fixed: this.getFixedHeight()
    };
  }
  getFixedHeight() {
    this.$.root.addClass('is-fixed');
    const height = this.$.root.outerHeight();
    this.$.root.removeClass('is-fixed');

    return height;
  }
  events() {
    this.$.window.on('scroll', () => this.handler());
    this.$.document.ready(() => this.handler());
  }
  handler() {
    if (this.state.animating) return;

    const offset = window.pageYOffset;
    const state = this.state.fixed;
    const dir = this.getDirection(offset);
    const bound = this.height.default * 4;

    if (offset > bound && !state && dir) {
      this.fix();
    }

    if ((offset < bound && state) || (!dir && state)) {
      this.unfix();
    }

    this.prevOffset = offset;
  }
  fix() {
    this.state.animating = true;

    this.$.root.addClass('is-fixed');
    this.$.root.css({
      opacity: 1,
      transform: `translateY(${this.height.fixed * -1}px)`
    });

    this.$.page.css({ paddingTop: this.height.default });

    $.trigger('header.fixed');

    anime({
      targets: this.$.root.get(0),
      duration: 400,
      translateY: 0,
      opacity: 1,
      easing: 'easeInOutCubic',
      complete: () => {
        this.state.animating = false;
        this.state.fixed = true;
      }
    });
  }
  unfix() {
    this.state.animating = true;

    anime({
      targets: this.$.root.get(0),
      duration: 400,
      opacity: 0,
      translateY: '-100%',
      easing: 'easeInOutCubic',
      complete: () => {
        this.$.page.removeAttr('style');
        this.$.root.removeAttr('style').removeClass('is-fixed');

        this.state.fixed = false;
        this.state.animating = false;

        $.trigger('header.unfixed');
      }
    });
  }
  getDirection(offset) {
    return offset < this.prevOffset ? 1 : 0;
  }
  static isFixed() {
    return $('.js-header').is('.is-fixed');
  }
  static getHeight() {
    return $('.js-header').outerHeight();
  }
}

Page.mount(Header, '.js-header');
