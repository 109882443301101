/**
 * Map
 */

import $ from 'jquery';
import Page from '~/page/page';

export default class Map extends Page {
  init() {
    this.param = {};

    this.elements({
      container: '.js-map-container'
    });

    $.getScript(
      '//maps.googleapis.com/maps/api/js?key=AIzaSyCGp6pOLSkRpcCD_AGJ4c9dlRZaHcuT9gs',
      () => {
        this.setParams();
        google.maps.event.addDomListener(window, 'load', () => this.setMap());
      }
    );
  }
  setParams() {
    const data = this.$.root.data('options');

    this.markers = data.markers;
    this.param.center = data.center;
    this.param.zoom = data.zoom;
  }
  setMap() {
    const options = {
      zoom: this.param.zoom,
      scrollwheel: false,
      center: new google.maps.LatLng(...this.param.center),
      disableDefaultUI: true,
      zoomControl: true
    };

    this.map = new google.maps.Map(this.$.container.get(0), options);
    this.generateMarkers();
  }
  generateMarkers() {
    this.markers.forEach(marker => {
      this.setMarker(
        marker.title,
        marker.coords,
        {
          url: marker.image,
          size: new google.maps.Size(50, 78),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(25, 75),
          scaledSize: new google.maps.Size(50, 78)
        },
        marker
      );
    });
  }
  setMarker(title, coords, icon, data) {
    new google.maps.Marker({
      position: new google.maps.LatLng(...coords),
      map: this.map,
      icon,
      optimized: false,
      title
    });
  }
}
Page.mount(Map, '.js-map');
