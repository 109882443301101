/**
 * product-slider
 */

import $ from 'jquery';
import 'slick-carousel';
import Page from '~/page/page';

export default class ProductSlider extends Page {
  init() {
    this.classList = {
      container: '.js-product-slider-container',
      thumbnail: '.js-product-slider-thumbnail'
    };

    this.elements({
      container: this.classList.container,
      thumbnail: this.classList.thumbnail,
      prev: '.js-product-slider-prev',
      next: '.js-product-slider-next'
    });

    this.options = {
      slider: this.$.container.data('options') || {},
      thumbnail: this.$.thumbnail.data('options') || {}
    };
  }
  events() {
    this.$.container.on('init', () => {
      this.$.root.addClass('is-inited');
    });

    this.$.thumbnail.on('init', (e, slick) => {
      this.setActive(slick, 0);
    });

    this.$.thumbnail.on('beforeChange', (e, slick, cur, index) => {
      this.setActive(slick, index);
    });
  }
  inited() {
    this.slider();
  }
  slider(options = {}) {
    this.$.container.slick(
      $.extend(
        {},
        {
          infinite: true,
          dots: false,
          arrows: false,
          prevArrow: this.$.prev,
          nextArrow: this.$.next,
          asNavFor: `${this.classList.thumbnail}, ${this.classList.container}`
        },
        this.options.slider,
        options.slider
      )
    );

    this.$.thumbnail.slick(
      $.extend(
        {},
        {
          infinite: true,
          dots: false,
          arrows: false,
          slidesToShow: 4,
          focusOnSelect: true,
          asNavFor: `${this.classList.thumbnail}, ${this.classList.container}`,
          accessibility: false
        },
        this.options.thumbnail,
        options.thumbnail
      )
    );
  }
  setActive(slick, index) {
    const $slides = slick.$slides;
    const $current = $slides.eq(index);

    $slides.removeClass('is-current');
    $current.addClass('is-current');
  }
  refresh() {
    const current = this.$.container.slick('slickCurrentSlide');

    this.$.container.slick('unslick');
    this.$.thumbnail.slick('unslick');

    this.slider({
      slider: {
        initialSlide: current
      }
    });

    this.goto(this.$.thumbnail, current);
  }
  goto(slider, index) {
    slider.slick('slickGoTo', index);
  }
  static refresh($node) {
    const $sliders = Page.getAllComponentInstance('ProductSlider');

    $.each($sliders, (i, slider) => {
      if (slider.$.root.closest($node).length) {
        slider.refresh();
      }
    });
  }
  static has($node) {
    return Boolean($node.find('.js-product-slider').length);
  }
}

Page.mount(ProductSlider, '.js-product-slider');
