import $ from 'jquery';
import Page from '~/page/page';

export default class ProductTable extends Page {
  init() {
    this.elements({
      list: '.js-product-table-list',
      item: '.js-product-table-item',
      button: '.js-product-table-button'
    });

    this.state = {};
    this.param = {
      max: this.$.root.data('max'),
      text: this.$.button.data('text') || {}
    };

    if (this.$.item.length > this.param.max - 1) {
      const $last = this.$.item.eq(this.param.max - 1);
      this.$.hidden = $last.nextAll();
      this.$.button.on('click', () => this.toggle());
      this.toggle();
    }
  }
  toggle() {
    if (this.$.root.is('.is-open')) {
      this.hide();
    } else {
      this.show();
    }
  }
  hide() {
    this.$.root.removeClass('is-open');
    this.$.button.text(this.param.text.show || 'Показать');
    this.$.hidden.slideUp(200);
  }
  show() {
    this.$.root.addClass('is-open');
    this.$.button.text(this.param.text.hide || 'Скрыть');
    this.$.hidden.slideDown(200);
  }
}

Page.mount(ProductTable, '.js-product-table');
