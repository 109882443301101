/**
 * form
 */

import $ from 'jquery';
import Page from '~/page/page';

export default class Form extends Page {
  init() {
    this.state = {};
    this.param = {};

    this.elements({
      button: '.js-form-button',
      footer: '.js-form-footer',
      fields: 'input, textarea'
    });
  }
  events() {
    this.$.root.on('input', () => this.validate());
    this.$.root.on('change', () => this.validate());
    this.$.footer.hover(
      () => this.showRequiredFields(),
      () => this.hideRequiredFields()
    );
  }
  showRequiredFields() {
    this.$.root.addClass('show-required');
  }
  hideRequiredFields() {
    this.$.root.removeClass('show-required');
  }
  validate() {
    // if (this.$.fields.is('[aria-invalid="false"]')) {
    //   this.enableButton();
    // } else {
    //   this.disableButton();
    // }
  }
  enableButton() {
    this.$.button.prop('disabled', false);
  }
  disableButton() {
    this.$.button.prop('disabled', true);
  }
}

Page.mount(Form, '.js-form');
