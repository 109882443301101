/**
 * scroll-top
 */

import $ from 'jquery';
import anime from 'animejs';
import Page from '~/page/page';

export default class Totop extends Page {
  init() {}
  events() {
    this.$.root.on('click', () => {
      anime({
        targets: $('body, html').get(),
        scrollTop: 0,
        easing: 'easeInOutCubic',
        duration: 800
      });

      return false;
    });
  }
}

Page.mount(Totop, '.js-totop');
