/**
 * modal
 */

import $ from 'jquery';
import VanillaModal from 'vanilla-modal';
import Page from '~/page/page';
import Slider from '~/product-slider/product-slider';

export default class Modal extends Page {
  init() {
    this.modal = new VanillaModal({
      modal: '.modal',
      modalInner: '.modal__inner',
      modalContent: '.modal__content',
      loadClass: 'modal-init',
      onClose: () => {
        this.onTransition().then(() => {
          if (!this.isOpen()) {
            this.$.root.removeClass(this.mode);
            this.bodyOverflowDisable();
          }
        });
      }
    });

    this.elements({
      content: '.js-modal-content'
    });
  }
  events() {
    this.$.document.on('click', '[data-modal-close]', () => this.close());
    this.$.document.on('click', '[data-modal]', e => this.handler(e));
  }
  handler(e) {
    const $trigger = $(e.currentTarget);

    const name = $trigger.data('modal');
    const id = `#modal-${name}`;
    const $target = $(id);

    if (!$target.length) return;

    e.preventDefault();

    this.mode = `is-${$target.data('mode')}` || '';

    if (this.mode === 'is-stores') {
      //const storeIndex = $trigger.data('id');
      const url = $trigger.attr('href'); //`http://localhost:3000/stores/${storeIndex}`;

      $.ajax(url).then(({ content }) => {
        $target.html(content);
        this.open(id);
      });
    } else {
      this.open(id);
    }
  }
  open(id) {
    this.$.root.addClass(this.mode);

    if (this.isOpen()) {
      this.close().then(() => {
        this.bodyOverflowEnable();
        this.modal.open(id);
      });
    } else {
      this.bodyOverflowEnable();
      this.modal.open(id);
    }

    if (Slider.has(this.$.root)) {
      Slider.refresh(this.$.root);
    }
  }
  close() {
    return new Promise(resolve => {
      setTimeout(() => {
        this.modal.close();
        this.$.root.removeClass(this.mode);
        this.bodyOverflowDisable();

        return this.onTransition().then(() => {
          resolve();
        });
      }, 400);
    });
  }
  onTransition() {
    return new Promise(resolve => {
      this.$.root.one('transitionend', () => {
        resolve();
      });
    });
  }
  isOpen() {
    return this.modal.isOpen;
  }
}

Page.mount(Modal, '.js-modal');
