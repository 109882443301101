/**
 * @FocusController
 */

import $ from 'jquery';

class FocusController {
  constructor() {
    this.$document = $(document);

    this.elements();
    this.events();
  }
  elements() {
    this.$elements = $('a, button');
  }
  events() {
    this.$document.click(e => {
      this.elements();

      const $target = $(e.currentTarget);

      if ($target != this.$elements) {
        this.$elements.removeClass('is-focused');
      }
    });
    this.$document.keydown(e => {
      if (e.keyCode === 9) {
        this.$elements.removeClass('is-focused');
        this.$elements.one('focus', e => {
          const $target = $(e.currentTarget);
          $target.addClass('is-focused');
        });

        this.$elements.one('blur', e => {
          const $target = $(e.currentTarget);
          if ($target.is('.is-focused')) {
            $target.removeClass('is-focused');
          }
        });
      }
    });

    this.$document.keyup(e => {
      if (e.keyCode === 9) {
        this.$elements.off('focus');
        this.$elements.off('blur');
      }
    });
  }
}

export default new FocusController();
