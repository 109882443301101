/**
 * image
 */

import $ from 'jquery';
import Page from '~/page/page';

import 'lightgallery';
import 'lightgallery/modules/lg-fullscreen';
import 'lightgallery/modules/lg-video';

export default class Gallery extends Page {
  init() {
    this.elements({
      list: '.js-gallery-list',
      item: '.js-gallery-item'
    });

    this.gallery();
  }
  gallery() {
    this.$.list.lightGallery({
      selector: '.js-image',
      download: false,
      counter: false,
      startClass: 'lg-start-fade',
      getCaptionFromTitleOrAlt: false
    });
  }
}

Page.mount(Gallery, '.js-gallery');
