/**
 * menu
 */

import $ from 'jquery';
import anime from 'animejs';

// import Keyframes from 'js/lib/keyframes';
import Page from '~/page/page';

export default class Menu extends Page {
  init() {
    this.state = {
      preventHover: false,
      animating: false
    };

    this.elements({
      overlay: '.js-menu-overlay',
      content: '.js-menu-content',
      link: '.js-menu-link',
      list: '.js-menu-list',
      open: '.js-menu-open',
      close: '.js-menu-close'
    });
  }
  events() {
    this.$.window.on('keydown', e => this.keydown(e));
    this.$.close.on('click', () => this.close());
    this.$.overlay.on('click', () => this.close());
    this.$.link.on('click', e => this.handler(e));
    this.$.open.on('click', () => this.open());
  }
  handler(e) {
    // if (!this.isMobileHorizontal) return;

    e.preventDefault();

    const $target = $(e.currentTarget);
    const $parent = $target.parent();
    const $list = $target.siblings(this.$.list);

    if ($parent.is('.is-open')) {
      $list.slideUp(300);
      $parent.removeClass('is-open');
    } else {
      $list.slideDown(300);
      $parent.addClass('is-open');
    }
  }
  keydown(e) {
    if (this.state.animating) return;
    if (e.keyCode === 27) {
      this.close();
    }
  }
  open() {
    if (this.state.animating) return;
    this.state.animating = true;

    this.$.overlay
      .css({
        transform: 'translateX(100%)',
        opacity: 0
      })
      .show();
    this.$.content
      .css({
        transform: 'translateX(100%)',
        opacity: 0
      })
      .show();

    this.setMargin();
    this.bodyOverflowEnable();
    this.$.body.addClass('menu-open');

    const timeline = anime.timeline({
      complete: () => {
        this.state.animating = false;
      }
    });

    timeline.add({
      targets: this.$.overlay.get(0),
      duration: 500,
      easing: 'easeOutCubic',
      opacity: 1,
      translateX: 0
    });

    timeline.add({
      targets: this.$.content.get(0),
      duration: 500,
      offset: '-=200',
      easing: 'easeOutCubic',
      opacity: 1,
      translateX: 0
    });
  }
  setMargin() {
    const padding = this.getScrollbarWidth();
    this.$.root.css({ marginRight: padding });
  }
  removeMargin() {
    this.$.root.removeAttr('style');
  }
  close() {
    if (this.state.animating) return;
    this.state.animating = true;

    const timeline = anime.timeline({
      complete: () => {
        this.state.animating = false;
        this.$.body.removeClass('menu-open');
        this.removeMargin();
        this.bodyOverflowDisable();
      }
    });

    timeline.add({
      targets: this.$.content.get(0),
      duration: 500,
      easing: 'easeOutCubic',
      opacity: 0,
      translateX: '100%'
    });

    timeline.add({
      targets: this.$.overlay.get(0),
      duration: 500,
      offset: '-=300',
      easing: 'easeOutCubic',
      opacity: 0,
      translateX: '100%'
    });
  }
}

Page.mount(Menu, '.js-menu');
