/**
 * Keyframes
 */

import $ from 'jquery';
import anime from 'animejs';

export default class Keyframes {
  /**
   * @param {object} $target jquery collection
   * @param {object} options duration, easing, offset, reverse
   * @return {object} animejs timeline
   * @static
   */
  static getTimeline($target, options = {}) {
    const $elements = $target.find('[data-animate]');

    const timeline = anime.timeline();
    const reverse = options.reverse || false;
    const isOut = options.isOut || false;

    $elements.each((i, element) => {
      const $element = $(element);

      const data = $element.data('animate');
      const keyframes = (isOut ? data.keyframesOut : data.keyframesIn) || {};
      const from = (reverse ? keyframes.to : keyframes.from) || {
        opacity: 0
      };
      const to = (reverse ? keyframes.from : keyframes.to) || {
        opacity: 1
      };
      const duration = data.duration || options.duration || 600;
      const easing = data.easing || options.easing || 'easeOutCubic';
      const offset = data.offset || options.offset || 400;

      // @ts-ignore
      const normalizeKeyframes = $.extend(
        {},
        ...Keyframes.getKeyframes(from, to, duration)
      );

      timeline.add(
        $.extend({}, normalizeKeyframes, {
          targets: element,
          easing,
          duration,
          autoplay: false,
          offset: i === 0 ? 0 : `-=${offset}`
        })
      );
    });

    return timeline;
  }

  /**
   * Normilize keyframes for animejs
   * @param {object} from
   * @param {object} to
   * @param {number} duration
   * @return {object} keyframes
   * @static
   */
  static getKeyframes(from, to, duration) {
    const keys = Object.keys(from);

    return keys.map(key => {
      return {
        [key]: [{ value: from[key], duration: 0 }, { value: to[key], duration }]
      };
    });
  }
}
